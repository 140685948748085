$primary: #e9ba97; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #333;


@import url('https://fonts.googleapis.com/css?family=Bahiana|Raleway');

body {
	background: lighten($blk, 3%);
	color: $wht;
	font-size: 22px;
	font-weight: 300;
	text-shadow: none;
	letter-spacing: 1px;
	font-family: 'Raleway',sans-serif;

	@media(max-width: 767px) {
		font-size: 18px;
	}
}

.row {
	border-bottom: 2px dashed lighten($blk, 10%);
	padding: 5em 0em;
}



h2 {
	font-family: 'Bahiana', sans-serif;
	font-size: 3em;
}


.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar {
	border-radius: 0;
	border: none;
	padding: 0.5em 0em;
	background: rgba($blk,0.75);
		@media (max-width: 767px) {
			padding-bottom: 0px;
		}

	.navbar-nav {
	    > li > a {
			text-align: center;
			height: 80px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			font-weight: 300;
			font-size: 18px;

			letter-spacing: 1px;
			color: $primary;

			&:active, &:focus {
				background: transparent;
			}

			@media (max-width: 767px) {
			    height: 40px;
			    font-size: 16px;
			    display: inline-block;
			    color: $blk;
			}

		    &:hover, &:focus {
		    	background: $primary;
		    	color: lighten($primary, 50%);
		    }
		}
	}
}

.navbar-default .navbar-toggle .icon-bar {
	background-color: $wht;
}


.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
		
		@media (max-width: 767px) {
			margin: 15px 25px 8px 0px;
		}
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}








































// Buttons
.btn {
	padding: 0.75em 1.75em;
	margin-right: 4px;
	margin-bottom: 4px;
	margin-top: 2em;
	font-family: $primary;
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 2px;
	// @include border-radius(0px);
	// @include transition(.5s);
	&.btn-md {
		padding: 10px 20px!important;
	}
	&.btn-lg {
		padding: 18px 36px!important;
	}
	&:hover, &:active, &:focus {
		box-shadow: none!important;
		outline: none!important;
	}
}

.btn-primary {
	background: $primary;
	color: $wht;
	border: 2px solid $primary;
	&:hover, &:focus, &:active {
		background: lighten($primary, 5%)!important;
		border-color: lighten($primary, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $primary;
		border: 2px solid $primary;
		&:hover, &:focus, &:active {
			background: $primary;
			color: $wht;
		}
	}
}

.btn-success {
	background: $secondary;
	color: $wht;
	border: 2px solid $secondary;
	&:hover, &:focus, &:active {
		background: darken($secondary, 5%)!important;
		border-color: darken($secondary, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $secondary;
		border: 2px solid $secondary;
		&:hover, &:focus, &:active {
			background: $secondary;
			color: $wht;
		}
	}
}
.btn-info {
	background: $secondary;
	color: $wht;
	border: 2px solid $secondary;
	&:hover, &:focus, &:active {
		background: darken($secondary, 5%)!important;
		border-color: darken($secondary, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $secondary;
		border: 2px solid $secondary;
		&:hover, &:focus, &:active {
			background: $secondary;
			color: $wht;
		}
	}
}
.btn-warning {
	background: $secondary;
	color: $wht;
	border: 2px solid $secondary;
	&:hover, &:focus, &:active {
		background: darken($secondary, 5%)!important;
		border-color: darken($secondary, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $secondary;
		border: 2px solid $secondary;
		&:hover, &:focus, &:active {
			background: $secondary;
			color: $wht;
		}
	}
}
.btn-danger {
	background: $secondary;
	color: $wht;
	border: 2px solid $secondary;
	&:hover, &:focus, &:active {
		background: darken($secondary, 5%)!important;
		border-color: darken($secondary, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $secondary;
		border: 2px solid $secondary;
		&:hover, &:focus, &:active {
			background: $secondary;
			color: $wht;
		}
	}
}
.btn-light {
	background: $wht;
	color: $wht;
	border: 2px solid $wht;
	&:hover, &:focus, &:active {
		background: $wht;
		border-color: $wht;
	}
	&.btn-outline {
		background: transparent;
		color: $wht;
		border: 2px solid $wht;
		&:hover, &:focus, &:active {
			background: $wht;
			color: $blk;
		}
	}
}
.btn-outline {
	background: none;
	border: 2px solid lighten($blk, 50%);
	font-size: 16px;
	// @include transition(.3s);
	&:hover, &:focus, &:active {
		box-shadow: none;
	}
}




#header {
	// height: 100vh;
	// width: 100vw;
	position: relative;
	text-align: center;
	// Viewport Units Buggyfill for IE9+
	// content: 'viewport-units-buggyfill; height:100vh; width: 100vw';
	// display: table;

	@media screen and (max-width: 991px) {
		display: block;
		height: auto!important;
		padding: 3em 0;
		// width: 100vw;
		// content: 'viewport-units-buggyfill; width: 100vw';
	}


	.text-wrap {
		// height: 100vh;
		// width: 100vw;
		// content: 'viewport-units-buggyfill; height:100vh; width: 100vw';

		display: flex;
		text-align: center;
		// vertical-align: middle;
		align-items: center;
		justify-content: center;
		@media screen and (max-width: 991px) {
			display: block;
			height: auto!important;
			padding: 3em 0;
			// height: 100vh;
			// width: 100vw;
			// content: 'viewport-units-buggyfill; height:100vh; width: 100vw';
		}
	}
	.intro-text {
		-webkit-transform: skewY(-7deg);
		-moz-transform: skewY(-7deg);
		-ms-transform: skewY(-7deg);
		-o-transform: skewY(-7deg);
		transform: skewY(-7deg);
		text-align: center;
	}
	h1 {
		font-size: 3.5em;
		line-height: 100px;
		font-weight: 600;
		letter-spacing: 15px;
		margin: 0;
		padding: 0;
		text-transform: uppercase;
		color: #fff;
		span {
			font-family: $secondary;
			font-style: italic;
			text-transform: none!important;
			color: $primary;
		}

		// @include transition(.5s);

		@media screen and (max-width: 1200px) {
			font-size: 2.9em;
			line-height: 80px;
			font-weight: 700;
			letter-spacing: 9px;
		}
		@media screen and (max-width: 991px) {
			font-size: 2.2em;
			line-height: 60px;
			font-weight: 700;
			letter-spacing: 9px;
		}
		@media screen and (max-width: 767px) {
			font-size: 1.5em;
			line-height: 50px;
			font-weight: 400;
			letter-spacing: 2px;
		}

	}
	span {
		margin: 0;
		padding: 0;
		color: white;
		text-transform: capitalize;
	}
	h2 {
		font-family: $secondary;
		font-weight: 400;
		font-size: 45px;
		line-height: 34px;
		font-style: italic;
		letter-spacing: .09em;
		color: $primary;
		text-transform: uppercase;

		@media screen and (max-width: 767px) {
			font-weight: 400;
			font-size: 28px;
			line-height: 30px;
		}
	}
}



.logo {
	max-width: 200px;
	padding-top: 0.25em;
	margin-bottom: 0em;
		@media screen and (max-width: 767px) {
			max-width: 170px;
			padding: 0.25em 0em 0.5em 1em;
		}
}

.noMar {
	margin-left: 0px;
	margin-right: 0px;
}

.is-sticky {
	// padding-top: 35px;
}


@media (max-width: 767px) {
	.navbar-right {
		margin-top: 0px;
		margin-bottom: 0px;
		background: rgba($wht,0.9);
	}

	img {
		margin-bottom: 3em;
	}
}


